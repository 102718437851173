.pagination {
    --bs-pagination-bg: var(--bs-white)
}

.page-item {

    &:first-child,
    &:last-child {
        .page-link {
            &:before {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
            }
        }
    }

    &:first-child {
        .page-link {
            &:before {
                background-image: url('/Icons/icon-chevrons-left.svg');
            }
        }
    }

    &:last-child {
        .page-link {
            &:before {
                background-image: url('/Icons/icon-chevrons-right.svg');
            }
        }
    }
}