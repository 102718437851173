.card {
  &.category {
    margin: 5px;
    width: 45%;
    max-width: 175px;

    &.category__back {
      width: 48px;
      transition: all .5s
    }

    @include media-breakpoint-up(md) {
      width: 175px;
    }

    &.active:after {
      @include media-breakpoint-up(md) {
        content: '\A';
        position: absolute;
        bottom: 0;
        height: 4px;
        background-color: $primary;
        width: 100%;
      }
    }
  }
}