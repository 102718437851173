@include media-breakpoint-down(sm) {
    .collapsible-sm[type=checkbox]~label {
        cursor: pointer;
    }

    .collapsible-sm[type=checkbox]:not(:checked)~div {
        height: 0;
        overflow: hidden;
        @include transition($transition-collapse);
    }

    .collapsible-sm[type=checkbox]:checked~div {
        height: auto;
    }

    
    .collapsible-sm[type=checkbox]:checked~label {
        .icon {
            &:before {
                background-image: url("/Icons/icon-chevron-up.svg");
            }
        }
    }
}