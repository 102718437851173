@include media-breakpoint-up(lg) {
  .product-finder {
    --tp-product-finder-image: url('/ProductFinder/product-finder--karton.png');

    &:before {
      content: '';
      width: 160px;
      height: 160px;
      margin-right: 1rem !important;
      background: no-repeat var(--tp-product-finder-image) center center;
      background-size: contain;
    }

    &.product-finder__carton {
      --tp-product-finder-image: url('/ProductFinder/product-finder--karton.png');
    }

    &.product-finder__bag {
      --tp-product-finder-image: url('/ProductFinder/product-finder--kordelzugbeutel.svg');
    }

    &.product-finder__pouch {
      --tp-product-finder-image: url('/ProductFinder/product-finder--versandtasche.svg');
    }
  }
}

.product-finder-search-fields-field__max-width {
  max-width: 86px;
  margin-right: 4px !important;
}

.carton-finder-main {
  .nav-link::after {
    content: '\A';
    position: absolute;
    bottom: 0;
    height: 4px;
    background-color: $gray-400;
    width: 100%;
  }

  .nav-link.active::after,
  .nav-link:hover::after {
    background-color: $secondary;
  }

  .nav-link {
    color: $gray-500;
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding-top: $spacer;
    padding-bottom: $spacer;
  }

  .carton-finder-label {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $gray-900;
  }

  .carton-finder-content {
    margin-bottom: 38px;
  }

  .nav-link .nav-icon {
    height: 1em;
    margin-right: 5px;
    filter: invert(86%) sepia(13%) saturate(149%) hue-rotate(149deg) brightness(79%) contrast(82%);
  }

  .nav-link.active {
    color: $secondary;

    .nav-icon,
    &.nav-icon {
      filter: invert(64%) sepia(80%) saturate(353%) hue-rotate(127deg) brightness(85%) contrast(98%);
    }
  }

  .icon-row {
    justify-content: center;

    .finder-icon {
      max-height: 300px;
      max-width: 300px;
    }
  }
}

.carton-finder-background {
  min-height: 200px;
  background: linear-gradient(to bottom, #f5f7f7, #d5dddf);
}