.table-product__variant {
    border-collapse: separate;
    border-spacing: 0 8px;

    td {
        background-color: var(--bs-white)
    }
}

@include media-breakpoint-up(xl) {
    .table-product__variant {

        tr,
        th {
            td {
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent;

                input {
                    border: 1px solid transparent;
                }
            }

            &:hover {
                td {
                    border-top: 1px solid var(--bs-gray-300);
                    border-bottom: 1px solid var(--bs-gray-400);
                    background-color: var(--bs-gray-200);

                    input {
                        border: 1px solid var(--bs-gray-500);
                    }
                }
            }

            &:not(:hover) {
                .table-cell__shadow-inset {
                    background: linear-gradient(90deg, #dfe8e8, #fff 2%, #fff 98%, #dfe8e8)
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .table-product__variant--responsive {
        display: block;
        min-width: 0;

        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
            display: block;
        }

        thead {
            position: absolute !important;
            width: 1px;
            height: 1px;
            overflow: hidden;
            clip: rect(1px, 1px, 1px, 1px);
        }

        th,
        td {
            height: auto;
            padding-right: 14px; //$table-responsive-padding;
            padding-left: 14px; //$table-responsive-padding;
            border-top-color: #000;

            &.table-cell__shadow-inset {
                background: #fff !important;
            }
        }

        [data-columnheader]:not(:last-child) {
            display: grid;
            grid-template-columns: 1fr 3fr;
            column-gap: 14px; //$table-responsive-column-gap;

            border-bottom-color: var(--bs-gray-200);

            &::before {
                content: attr(data-columnheader);
                position: static;
                font-weight: 600; //$table-responsive-columnheader-font-weight;
                vertical-align: top;
                hyphens: auto;
            }
        }

        tr+tr {
            margin-top: 14px;
        }

        td:last-child {
            button {
                width: 100%
            }
        }
    }
}

.table-product__product-number {
    display: inline-block;
}

@include media-breakpoint-up(xl) {
    .table-product__product-number {
        &:hover {
            .product-number__popup {
                display: inline-block;
                z-index: 9999;

                opacity: 1;
                transition: opacity 0.25s ease-in-out;
                transition-delay: 250ms, 400ms;
            }
        }
    }

    .product-number__popup {
        position: absolute;

        display: none;

        top: 50%;
        right: 100%;

        min-width: 118px;

        opacity: 0;
        z-index: 9999;

        transform: translateY(-50%);

        transition: opacity 0.25s ease-in-out;
        transition-delay: 250ms, 400ms;
    }
}