.heading .heading__container {
    min-height: 40px;

    @include media-breakpoint-down(sm) {
        padding: 0
    }
}

.heading:not(.heading--search) .H1-Headline-White~.heading__description,
.heading:not(.heading--search) .H1-Headline-White:not(:has(~.heading__description)) {
    @include media-breakpoint-up(sm, (sm: 650px)) {
        margin-right: 160px;
    }

    @include media-breakpoint-up(md) {
        margin-right: 230px;
    }
}

.heading.heading--search .H1-Headline-White~.heading__description,
.heading.heading--search .H1-Headline-White:not(:has(~.heading__description)) {
    @include media-breakpoint-up(lg) {
        margin-right: 230px;
    }
}

.heading .heading__description {
    flex-grow: 1 !important;

    @include media-breakpoint-up(xl) {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }
}