@each $color, $value in $theme-colors {
    .action-box--#{$color} {
      background-color: rgba($value, 0.4);
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      gap: 5px;
  
      img {
        width: 1em;
        height: 1em;
      }
    }
  }
  
  .action-box-primary.border-primary {
    border-color: $primary !important;
  }
  