.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-bg: white;

    .filter {
        transition: height 0.3s ease-in-out
    }
}

.offcanvas-body {
    .btn-outline-dark {
        border-color: #ced4da;
    }
}