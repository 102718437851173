.btn {
    --bs-btn-border-radius: 0;
    --bs-btn-active-border-color: transparent;
    gap: 5px;
}

.btn--inactive {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color);
    opacity: var(--bs-btn-opacity);

    &:hover,
    &:focus,
    &:active,
    :not(.btn-check):active {
        --bs-btn-active-bg: var(--bs-btn-bg);
        --bs-btn-active-color: var(--bs-btn-bg);
        --bs-btn-active-border-color: var(--bs-btn-border-color);

        --bs-btn-hover-bg: var(--bs-btn-bg);
        --bs-btn-hover-color: var(--bs-btn-bg);
        --bs-btn-hover-border-color: var(--bs-btn-border-color);

        .icon.icon--transition {
            &:hover {

                &:before,
                &:after {
                    filter: invert(1);
                }
            }

            &:before,
            &:after {
                filter: invert(1);
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, filter 0.15s ease-in-out;
            }
        }
    }
}

.btn-link {
    --bs-btn-font-weight: 700;
}

.btn-secondary {
    --bs-btn-font-weight: 700;
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-color: var(--bs-btn-color);
    --bs-btn-disabled-bg: var(--bs-gray-500);
    --bs-btn-disabled-border-color: var(--bs-gray-500);
}

.btn-outline-secondary {

    &:hover,
    &:focus,
    &:active {
        background-color: transparent !important
    }
}