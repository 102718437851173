.breadcrumb-item-home {
    --bs-breadcrumb-item-home-divider: url('/Icons/14px/icon-home.png');

    a {
        height: 21px;
        line-height: 21px;

        &:before {
            position: relative;
            top: 1px;
            color: var(--bs-breadcrumb-divider-color);
            content: var(--bs-breadcrumb-item-home-divider);
        }
    }
}

.breadcrumb-item-last::before {
    filter: invert(59%) sepia(78%) saturate(395%) hue-rotate(127deg) brightness(96%) contrast(86%);
}

.breadcrumb-item {
    display: flex;
    align-items: center;
    line-height: 21px;
}

.breadcrumb-item a,
.breadcrumb-item.active {
    display: inline-flex;
    color: $black;
    text-decoration: none;
    align-items: center;
    font-size: 14px;
}