.cart-preview-image {
  height: 75px;
  width: 75px;
  border: 1px solid $gray-400;
  display: flex;
  padding: 1px;
}

.amount-form-control {
  min-width: 80px;
}

.mh-6 {
  min-height: 65px;
}