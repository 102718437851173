.form-control {
    --bs-form-control-bg: var(--bs-white);
    --bs-border-radius: 0;
    background-color: var(--bs-form-control-bg);

    &:focus {
        box-shadow: none;
        border-color: $secondary;
        background-color: var(--bs-form-control-bg);
    }

    &.is-invalid {
        &:focus {
            box-shadow: none;
        } 
    }
}