/*! purgecss start ignore */

.badge {
    width: fit-content;
    height: 30px;

    font-size: 14px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.57 !important;
    letter-spacing: normal !important;
    color: $white !important;


    transition: all 0.5s;
    -webkit-transition: all 0.5s;

    &.badge-hover {
        max-width: 30px;
    }

    &:hover {
        max-width: 100%;
    }

    &:before {
        position: absolute;
    }

    .badge-text {
        padding-left: 23px;
        overflow: hidden;
    }
}

@each $color,
$value in $theme-colors {
    .badge-#{$color} {
        background-color: $value !important;
    }
}

.badge-action {
    background-color: $action !important;
}

.icon--badge__action {
    &:before {
        background-image: url('/Badge/aktion.svg');
    }
}

.badge-bestprice {
    background-color: $primary !important;
}

.icon--badge__bestprice {
    &:before {
        background-image: url('/Badge/bestprice.svg');
    }
}

.icon--badge__closeout {
    &:before {
        background-image: url('/Badge/auslauf.svg');
    }
}

.badge-new {
    background-color: $colors-badge-new !important;
}

.icon--badge__new {
    &:before {
        background-image: url('/Badge/neu.svg');
    }
}

.badge-eco {
    background-color: $eco !important;
}

.icon--badge__eco {
    &:before {
        background-image: url('/Badge/umweltfreundlich.svg');
    }
}


.icon--badge__eco-1 {
    &:before {
        background-image: url('/Badge/biologisch_abbaubar.svg');
    }
}

.icon--badge__eco-2 {
    &:before {
        background-image: url('/Badge/industriell_abbaubar.svg');
    }
}

.icon--badge__eco-3 {
    &:before {
        background-image: url('/Badge/aus_nachwachsenden rohstoffen.svg');
    }
}

.icon--badge__eco-4 {
    &:before {
        background-image: url('/Badge/recyclebar.svg');
    }
}

.icon--badge__eco-5 {
    &:before {
        background-image: url('/Badge/aus_recyclingmaterial.svg');
    }
}

.icon--badge__eco-6 {
    &:before {
        background-image: url('/Badge/reduzierter_materialeinsatz.svg');
    }
}

.icon--badge__eco-8 {
    &:before {
        background-image: url('/Badge/wiederverwendbar.svg');
    }
}

.icon--badge__eco-umweltsignets_vegan {
    &:before {
        background-image: url('/Badge/vegan.svg');
    }
}

.icon--badge__eco-umweltsignet_fsc {
    &:before {
        background-image: url('/Badge/fsc.svg');
    }
}

.badge-promotion {
    background-color: $danger !important;
}

.icon--badge__promotion {
    &:before {
        background-image: url('/Badge/aktion.svg');
    }
}

.icon--badge__topseller {
    &:before {
        background-image: url('/Badge/beliebt.svg');
    }
}

/*! purgecss end ignore */
