.delivery__popup {
    display:none;
}

@include media-breakpoint-up(xl) {
    .delivery {
        &:hover {
            .delivery__popup {
                display: inline-block;
                opacity: 1;
                transition: opacity 0.8s ease-in-out;
                transition-delay: 250ms, 400ms;
            }
        }
    }

    .delivery__popup {
        position: absolute;

        display: none;

        top: 50%;
        right: 100%;

        min-width: 200px;

        opacity: 0;

        transform: translateY(-50%);

        transition: opacity 0.8s ease-in-out;
        transition-delay: 250ms, 400ms;
    }
}