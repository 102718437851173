/*! purgecss start ignore */

.add-to-compare {
    width: fit-content;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;

    >span {
        width: 0;
        overflow: hidden;
    }

    &:hover {

        border-radius: var(--bs-border-radius);
        background-color: $secondary;

        &:before {
            filter: brightness(0) invert(1) !important;
        }

        >span {
            width: auto;
            color: $white;
            padding-right: 0.25rem !important
        }
    }
}

/*! purgecss end ignore */