.product-title {

    a:link,
    a:visited,
    a:hover,
    a:active {
        text-decoration: none;
        color: black;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
    }
}

.product-card-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.category-product-image {
    width: auto;
}

.mt-px__42 {
    margin-top: 42px
}