.swiper {
  --swiper-navigation-color: var(--bs-white);

  position: unset !important;

  &.dark {
    --swiper-navigation-color: #000000;
  }
}

.swiper-slide {
  height: auto;
}

.swiper-button-prev,
.swiper-button-next {
  .swiper-navigation-size-small & {
    transform: scale(0.55);
  }
}

.swiper-button-next {
  .swiper-navigation-outside>& {
    position: absolute;
    right: -30px;
  }
}

.swiper-button-prev {
  .swiper-navigation-outside>& {
    position: absolute;
    left: -30px;
  }
}

.swiper-pagination {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: var(--bs-gutter-x,.75rem);
  padding-left: var(--bs-gutter-x,.75rem);
  gap: 5px;
 // --bs-gutter-x: 0rem !important;

  @if $enable-grid-classes {

    // 100% wide container at all breakpoints
    & {
      @include make-container();
    }

    // Responsive containers that are 100% wide until a breakpoint
    @each $breakpoint,
    $container-max-width in $container-max-widths {
      &-#{$breakpoint} {
        @extend .container-fluid;
      }

      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        %responsive-container-#{$breakpoint} {
          max-width: $container-max-width;
        }

        // Extend each breakpoint which is smaller or equal to the current breakpoint
        $extend-breakpoint: true;

        @each $name,
        $width in $grid-breakpoints {
          @if ($extend-breakpoint) {
            &#{breakpoint-infix($name, $grid-breakpoints)} {
              @extend %responsive-container-#{$breakpoint};
            }

            // Once the current breakpoint is reached, stop extending
            @if ($breakpoint ==$name) {
              $extend-breakpoint: false;
            }
          }
        }
      }
    }
  }

}


.swiper-pagination-bullet {
  width: 30px;
  height: 3px;
  background-color: $gray-200;
  opacity: 0.5;
}

.swiper-pagination-bullet__secondary {
  background-color: $gray-900;
}

.swiper-pagination-bullet__left-0 {
  padding-left: 0 !important
}

.swiper-pagination-bullet-active {
  background-color: white !important;
  opacity: 1;
}

.swiper-pagination-bullet-active__secondary {
  background-color: $secondary !important;
  opacity: 1;
}

.swiper-pagination-align-left {
  .swiper-pagination {
    padding-left: 0;
    padding-right: 0;
  }
}

.swiper-pagination-align-inside {
  .swiper-pagination {
    position: absolute;
    bottom: 0;
    z-index: 9999;
  }
}

.price-swiper {
  width: 100%;
  max-width: 170px;

  >.swiper-container {
    width: calc(100% - 30px);
  }

  @media screen and (min-width: 1270px) {
    max-width: 250px;
  }

  @media screen and (min-width: 1340px) {
    max-width: 320px;
  }

  @media screen and (min-width: 1410px) {
    max-width: 390px;
  }
}

.swiper-slider__grid-1 {
  width: calc(100% - var(--bs-gutter-x)) !important;
}

.swiper-slider__grid-2 {
  width: calc(50% - var(--bs-gutter-x)) !important;
}

.swiper-slider__grid-3 {
  width: calc(33%) !important;
}

.swiper-slider__grid-4 {
  width: calc(25% - var(--bs-gutter-x)) !important;
}