@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

@import "colors";

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1410px,
);

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  danger: $danger,
  info: $info,
  warning: $warning,
  dark: $dark,

  orange-500: $orange-500,
  eco: $eco,
  badge-new: $colors-badge-new,
  action: $action,
  footer-light: $footer-light,

  gray-100: $gray-100,
  gray-200: $gray-200,
  gray-300: $gray-300,
  gray-400: $gray-400,
  gray-500: $gray-500,
  gray-600: $gray-600,
  gray-700: $gray-700,
  gray-800: $gray-800,
  gray-900: $gray-900,
  gray-custom-100: #b8bcbc,

  green-custom-100: #e4f4d9,
  green-custom-400: #7ac943,
  custom-f36e08: $custom-f36e08
);

$icon-sizes: (
  xs: 10px,
  sm: 14px,
  md: 22px,
  lg: 32px,
  xl: 48px,
  xxl: 64px,
  xxxl: 156px,
  12: 12px,
  14: 14px,
  16: 16px,
  24: 24px,
  36: 36px
);

$icon-color-filters: (
  primary: invert(64%) sepia(69%) saturate(1307%) hue-rotate(341deg) brightness(103%) contrast(101%),
  secondary: invert(67%) sepia(19%) saturate(1442%) hue-rotate(127deg) brightness(95%) contrast(85%),
  success: invert(62%) sepia(82%) saturate(2097%) hue-rotate(38deg) brightness(102%) contrast(98%),
  danger: invert(30%) sepia(64%) saturate(2329%) hue-rotate(332deg) brightness(88%) contrast(95%),
  info: invert(47%) sepia(82%) saturate(1925%) hue-rotate(176deg) brightness(104%) contrast(103%),
  warning: invert(77%) sepia(53%) saturate(1037%) hue-rotate(342deg) brightness(106%) contrast(101%),
  dark: invert(21%) sepia(8%) saturate(816%) hue-rotate(169deg) brightness(88%) contrast(89%),
  action: invert(41%) sepia(78%) saturate(2172%) hue-rotate(166deg) brightness(87%) contrast(101%),
  orange-500: invert(49%) sepia(93%) saturate(1094%) hue-rotate(351deg) brightness(100%) contrast(99%),
  eco: invert(64%) sepia(37%) saturate(653%) hue-rotate(98deg) brightness(96%) contrast(98%),
  colors-badge-new: invert(12%) sepia(71%) saturate(4583%) hue-rotate(328deg) brightness(98%) contrast(100%),
  gray-500:invert(77%) sepia(10%) saturate(184%) hue-rotate(149deg) brightness(87%) contrast(90%),
  white: brightness(0) invert(1),
  black: brightness(0),
  tp: invert(62%) sepia(62%) saturate(444%) hue-rotate(127deg) brightness(91%) contrast(96%)
);

$enable-dark-mode: false;
$enable-negative-margins: true;

$footer-gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0));

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-border-colors: $theme-colors;

$image-box-height: 451px;

$navbar-primary-height: 62px;
$navbar-categories-height: 54px;
$navbar-categories-height-desktop: 133px;
$trustedshops-mobile-height: 55px;

$navbar-usp-height: 37px;

$breadcrumb-divider: url('/Icons/icon-chevron-right.svg');