body:has(#all-products:checked) {
    overflow: hidden
}

.mega-menu {
    display: none;

    position: absolute;
    z-index: 9999;

    scrollbar-width: thin;
    overflow-y: auto;

    left: 0;
    top: -16px;

    width: 100vw;
    height: calc(100vh - $navbar-categories-height + 6px);
    height: calc(var(--app-height) - $navbar-categories-height + 6px);

    background-color: $gray-100;

    @include media-breakpoint-up(md) {
        top: calc(100% + 1px);

        height: unset;
        max-height: calc(100vh - $navbar-categories-height-desktop - 2px);

        padding: 32px 21px 50px 42px;

        box-shadow: 0 2px 6px 0 rgba(41, 34, 33, 0.2);
    }
}

.submenu {
    .icon:before {
        position: relative;
        top: 4px;
        margin-right: 4px;
    }
}

[type=checkbox]:checked~.mega-menu {
    display: block;
}