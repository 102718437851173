$white: #ffffff;
$black: #000000;
$secondary: #00a3a6;
$primary: #ff9a25;
$success: #8bc003;
$danger: #dc3545;
$info: #0dabfd;
$warning: #ffc61b;
$light: #f8f9fa;
$dark: #343a40;
$gray-100: #f3f7f7;
$gray-200: #e7ebec;
$gray-300: #dde2e2;
$gray-400: #cad2d4;
$gray-500: #a2abae;
$gray-600: #7a8385;
$gray-700: #515758;
$gray-800: #383e3e;
$gray-900: #252a2b;
$turqoise-100: #b2f1ed;
$turqoise-200: #89eee7;
$turqoise-300: #6ddfd7;
$turqoise-400: #42cac0;
$turqoise-500: #24b3a8;
$turqoise-600: #15958c;
$turqoise-700: #057a72;
$turqoise-800: #005953;
$turqoise-900: #003632;
$orange-100: #ffe5d0;
$orange-200: #fecba1;
$orange-300: #feb272;
$orange-400: #fd9843;
$orange-500: #fd7e14;
$orange-600: #ca6510;
$orange-700: #984c0c;
$orange-800: #653208;
$orange-900: #331904;
$action: #008fc0;
$eco: #afca0b;
$colors-badge-new: #b60d48;
$footer-light: #464b4b;
$custom-f36e08: #f36e08;