.search-field-entries {
  @if $navbarIncludeB2C ==1 {
    top: 200px;
    max-height: calc(100vh - 204px);
  }

  @else {
    top: 200px - $navbar-usp-height;
    max-height: calc(100vh - (204px - $navbar-usp-height));
  }

  @include media-breakpoint-up(sm, (sm: 650px)) {
    @if $navbarIncludeB2C ==1 {
      top: 145px;
      max-height: calc(100vh - 149px);
    }

    @else {
      top: 145px - $navbar-usp-height;
      max-height: calc(100vh - (149px - $navbar-usp-height));
    }
  }

  @include media-breakpoint-up(md) {
    top: 92px;
    max-height: calc(100vh - 96px);
  }

  @include media-breakpoint-up(xxl) {
    top: 39px;
    max-height: calc(100vh - 80px);
  }
}