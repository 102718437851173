.news-1column {
    width: 100%;
    height: 239px;
}

@include media-breakpoint-up(sm) {
    .news-1column {
        height: 388px;
    }
}

.news-gradient {
    &:before {
        content: '';

        position: absolute;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background-size: cover;
        background-image: linear-gradient(transparent 10%, rgba(0, 0, 0, .85));
    }
}