.cms-element-cmscollapse {
    .cms-element-cmscollapse-accordion {
        .cms-element-cmscollapse-accordion-card {
            .card-header {

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    margin-bottom: 0;
                }

                .sw-icon {
                    position: absolute;
                    top: 13px;
                    right: 21px;
                }

                .btn {
                    padding-left: 0;
                    padding-top: 0;
                    padding-bottom: 0;

                    .icon--small-arrow-medium-down,
                    .icon--small-default-plus-line-medium {
                        display: none;
                    }

                    .icon--small-arrow-medium-up,
                    .icon--small-default-minus-medium {
                        display: block;
                    }

                    &.collapsed {

                        .icon--small-arrow-medium-down,
                        .icon--small-default-plus-line-medium {
                            display: block;
                        }

                        .icon--small-arrow-medium-up,
                        .icon--small-default-minus-medium {
                            display: none;
                        }
                    }
                }
            }

            .card-body {
                padding: 20px 20px 20px 20px;
                width: 100%;
            }

            .card-body.column-count-1 {
                column-count: 1;
            }

            .card-body.column-count-2 {
                column-count: 1;

                @media screen and (min-width: 768px) {
                    column-count: 2;
                }
            }

            .card-body.column-count-3 {
                column-count: 1;

                @media screen and (min-width: 768px) {
                    column-count: 2;
                }

                @media screen and (min-width: 992px) {
                    column-count: 3;
                }
            }

            .card-body.has--column-rule {
                column-rule-color: #4a545b;
                column-rule-width: 1px;
                column-rule-style: solid;
            }

            .card-body.has--column-span {

                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                .has-column-span-all {
                    column-span: all;
                }
            }

            .collapse {
                max-height: 0;
                transition: max-height 0.5s ease-out;

                &.show {
                    max-height: 9999px;
                    transition: max-height 0.5s ease-in;
                }
            }
        }

        .cms-element-cmscollapse-accordion-card.is--complete-clickable {
            .card-header {
                padding: 0;

                .sw-icon {
                    position: relative;
                    top: 0;
                    right: 0;
                    float: right;
                }

                .btn {
                    display: block;
                    text-align: left;
                    padding: 12px 20px 12px 20px;
                }
            }
        }
    }
}

.float-start {
    float: left !important
}