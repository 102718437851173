.image-slider-height {
    height: 175px;

    @include media-breakpoint-up(sm) {
        height: 250px;
    }

    @include media-breakpoint-up(lg) {
        height: 328px;
    }

    @include media-breakpoint-up(xl) {
        height: 400px;
    }
}

.image-slider-background {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.stoerer {
    right: 4.3%;
    bottom: 5.1%;
    width: 90px;
    height: 90px;
    border-radius: 45px;

    @include media-breakpoint-up(sm) {
        right: 5.6%;
        bottom: 16.8%;
    }

    @include media-breakpoint-up(md) {
        right: 9.4%;
        bottom: 9.6%;
    }

    @include media-breakpoint-up(lg) {
        width: 180px;
        height: 180px;
        border-radius: 90px;
        right: 4.6%;
        bottom: 6.2%;
    }

    @include media-breakpoint-up(xxl) {
        right: 3.9%;
        bottom: 7.8%;
    }
}