.description {
    ul {
        padding-left: 1rem;

        li {
            padding-left: 15px;
        }

        li::marker {
            content: url('/Icons/icon-check.svg');
        }
    }
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: $gray-100 !important;
  --bs-table-accent-bg: $gray-100;
}

.image-box-height {
  height: calc($image-box-height / 2);
  @include media-breakpoint-up(md) {
    height: $image-box-height;
  }
}


/*
.product-recommendation-container {
    width: 80vw !important;
    // TODO what is py? did you mean px ? :)
    height: 20py !important;
    background-color: grey !important;
  }
  
  .product-description-container {
    width: 80vw !important;
    // TODO what is py? did you mean px ? :)
    height: 20py !important;
    background-color: grey !important;
  }
  
  .product-detail-info {
    display: flex;
    flex-wrap: wrap;
  }
  
  .product-detailed-information-table {
    font-size: 16px;
    table {
      table-layout: fixed;
    }
  
    .table-striped > tbody > tr:nth-child(odd) > td,
    .table-striped > tbody > tr:nth-child(odd) > th {
      background-color: $gray-100 !important;
      --bs-table-accent-bg: $gray-100;
    }
  
    td,
    th {
      word-wrap: break-word;
      padding: 13px;
    }
  
    tr {
      border-bottom: 1px solid $gray-200;
    }
  
    iframe {
      display: none;
    }
  }
  
  .product-description, .product-detailed-information-description {
    ul {
      padding-left: 1rem;
  
      li {
        padding-left: 15px;
      }
  
      li::marker {
        content: url('/Icons/14px/icons-14-px-icon-check.svg');
      }
    }
  }
  */