/*! purgecss start ignore */

.icon {

    &:before,
    &:after {
        width: 22px;
        height: 22px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }

    &.rounded-circle {

        &:before,
        &:after {
            transform: scale(0.7);
        }
    }

    &.dropdown-toggle {
        &::after {
            content: none;
        }
    }
}

.icon:not(.icon--reversed) {
    &:before {
        content: '';
    }
}

.icon--reversed {
    padding-right: 30px;

    &:after {
        right: 0;
        content: '';
    }
}

.icon--align__center {
    position: relative;

    &:before {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.icon--valign__middle {
    position: relative;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.icon--transition {
    &:hover {

        &:before,
        &:after {
            filter: invert(1);
        }
    }

    &:before,
    &:after {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, filter 0.15s ease-in-out;
    }
}

.icon--arrow-up {
    &:before {
        background-image: url('/Icons/icon-arrow-up.svg');
    }
}

.icon--search {
    &:before {
        background-image: url('/Icons/14px/icon-search.png');
    }
}

.icon--phone {
    &:before {
        background-image: url('/Icons/icon-phone.svg');
    }
}

.icon--clock {
    &:before {
        background-image: url('/Icons/29px/icon-clock.png');
    }
}

.icon--email {
    &:before {
        background-image: url('/Icons/29px/icon-email.png');
    }
}

.icon--coupon {
    &:before {
        background-image: url('/Icons/29px/icon-coupon.png');
    }
}

.icon--chevron-left {
    &:before {
        background-image: url('/Icons/icon-chevron-left.svg');
    }
}

.icon--chevron-right {

    &:after,
    &:before {
        background-image: url('/Icons/icon-chevron-right.svg');
    }
}

.icon--chevron-up {
    &:before {
        background-image: url('/Icons/icon-chevron-up.svg');
    }
}

.icon--chevron-down {
    &:before {
        background-image: url('/Icons/icon-chevron-down.svg');
    }
}

.icon--x,
.icon--close {

    &:before,
    &:after {
        background-image: url('/Icons/14px/icon-x.png');
    }
}

.icon--filter {
    &:before {
        background-image: url('/Icons/icon-filter.svg');
    }
}

.icon--check,
.icon--success {
    &:before {
        background-image: url('/Icons/icon-check.svg');
    }
}

.icon--wishlist {
    &:before {
        background-image: url('/Icons/icon-wishlist.svg');
    }
}

.icon--wishlist-checked {
    &:before {
        background-image: url('/Icons/icon-wishlist-checked.svg');
    }
}

.icon--compare {
    &:before {
        background-image: url('/Icons/icon-compare.svg');
    }
}

.icon--menu {
    &:before {
        background-image: url('/Icons/icon-menu.svg');
    }
}

.icon--cart {
    &:before {
        background-image: url('/Icons/32px/icon-cart-full.png');
    }
}

.icon--cart__buy {
    &:before {
        background-image: url('/Icons/icon-cart-buy.svg');
    }
}

.icon--trash {
    &:before {
        background-image: url('/Icons/icon-trash.svg');
    }
}

.icon--wiki {
    &:before {
        background-image: url('/Icons/icon-wiki.svg');
    }
}

.icon--service {

    &:before,
    &:after {
        background-image: url('/Icons/32px/icon-service.png');
    }
}

.icon--service__dark {
    &:before {
        background-image: url('/Icons/icon-service-dark.svg');
    }
}

.icon--user {

    &:before,
    &:after {
        background-image: url('/Icons/32px/icon-user.png');
    }
}

.icon--list {

    &:before,
    &:after {
        background-image: url('/Icons/32px/icon-list.png');
    }
}

.icon--envelope {
    &:before {
        background-image: url('/Icons/icon-envelope.svg');
    }
}

.icon--envelope-closed {
    &:before {
        background-image: url('/Icons/icon-envelope-closed.svg');
    }
}

.icon--shipping {
    &:before {
        background-image: url('/Icons/icon-shipping.svg');
    }
}

.icon--shipping__dark {
    &:before {
        background-image: url('/Icons/icon-shipping-dark.svg');
    }
}

.icon--payment-methods {
    &:before {
        background-image: url('/Icons/icon-payment-methods.svg');
    }
}

.icon--payment-methods__dark {
    &:before {
        background-image: url('/Icons/icon-payment-methods-dark.svg');
    }
}

.icon--box-offer {
    &:before {
        background-image: url('/Icons/icon-box-offer.svg');
    }
}

.icon--box-offer__dark {
    &:before {
        background-image: url('/Icons/icon-box-offer-dark.svg');
    }
}

.icon--dmsz {
    &:before {
        background-image: url('/Icons/icon-dmsz.svg');
    }
}

.icon--dmsz__dark {
    &:before {
        background-image: url('/Icons/icon-dmsz-dark.svg');
    }
}

.icon--co-2-neutrale-website {
    &:before {
        background-image: url('/Icons/icon-co-2-neutrale-website.svg');
    }
}

.icon--co-2-neutrale-website__grey {

    &:before,
    &:after {
        height: 36px;
        width: 100px;
    }

    &:before {
        background-image: url('/Icons/icon-co-2-neutrale-website-grey.svg');
    }
}

.icon--minus {
    &:before {
        background-image: url('/Icons/14px/icon-minus.png');
    }
}

.icon--plus {
    &:before {
        background-image: url('/Icons/14px/icon-plus.png');
    }
}

.icon--share {
    &:before {
        background-image: url('/Icons/icon-share.svg');
    }
}

.icon--bag {
    &:before {
        background-image: url('/Icons/icon-bag.svg');
    }
}

.icon--box {
    &:before {
        background-image: url('/Icons/icon-box.svg');
    }
}

.icon--persoehnliche_beratung {
    &:before {
        background-image: url('/Icons/icon-persoehnliche_beratung.svg');
    }
}

.icon--individuelle_verpackungsberatung {
    &:before {
        background-image: url('/Icons/icon-individuelle_verpackungsberatung.svg');
    }
}

.icon--standorte {
    &:before {
        background-image: url('/Icons/icon-standorte.svg');
    }
}

.icon--height__78 {
    height: 78px
}

@each $size, $value in $icon-sizes {
    .icon--size#{'__' + $size} {
        min-width: $value;

        @if $value < 156 {
            min-height: $value;
        }

        &:before,
        &:after {
            width: $value !important;

            @if $value < 156 {
                height: $value !important;
            }

            @else {
                height: auto;
            }
        }

        &.icon--valign__middle:not(.icon--reversed) {
            &:not(:empty) {
                text-indent: $value + 4;
            }

            &:before,
            &:after {
                left: 0;
            }
        }
    }
}

@each $color, $filter in $icon-color-filters {
    .icon--#{'' + $color} {

        &:before,
        &:after {
            filter: $filter !important;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $color, $filter in $icon-color-filters {
            .icon#{$infix}--#{'' + $color} {

                &:before,
                &:after {
                    filter: $filter !important;
                }
            }
        }
    }
}


.icon-circle {
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    display: inline-flex;

    .icon {
        transform: scale(0.7);
        margin: 1px;
    }
}

@each $color, $value in $theme-colors {
    .icon-circle-#{$color} {
        background: $value;
    }
}

.icon-circle-white {
    background: white;
}

.icon-circle-unchecked {
    border: solid 1px $gray-400;
}

.icon-sm {
    width: 14px !important;
    height: 14px !important;
}

.icon-md {
    width: 20px !important;
    height: 20px !important;
}

.icon-lg {
    width: 24px !important;
    height: 24px !important;
}

.icon.disabled {
    opacity: 0.4;
    filter: alpha(opacity=40) brightness(0.2);
    /* msie */
}

/*! purgecss end ignore */