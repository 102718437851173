$navbar-nav-link-padding-x: 19px;

$navbar-padding-y: null;
$navbar-padding-x: calc(var(--bs-gutter-x) * 0.5);

.navbar-expand {
  .navbar-nav {
    .nav-link {
      --bs-navbar-nav-link-padding-x: 12px;
    }
  }
}

.nav-item {
  position: relative;

  &:hover,
  &.is-active {
    &:after {
      @include media-breakpoint-up(md) {
        content: '\A';
        position: absolute;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        background-color: $primary;
        width: calc(100% - (var(--bs-navbar-nav-link-padding-x) * 2.25));
      }
    }
  }
}

.category-nav-link {
  display: flex !important;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@include media-breakpoint-up(md) {
  .nav-link {
    padding: 0.5rem;
    white-space: nowrap;
  }
}

.scroll {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.button--active {
  width: 10px;
  height: 10px;
}
