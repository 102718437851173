/*
.form-check {
  display: inline-flex;
  gap: 0.25rem !important;
  align-items: center !important;

  .form-check-input {
    margin-top: auto;
  }
}
*/

.form-check-input {
  --bs-form-check-bg: var(--bs-white);
  
    &[type='checkbox'] {
      margin-top: 0;
      border-radius: 0;
    }
  
    &:checked {
      background-color: $secondary;
      border: 0px;
    }
  
    
    box-shadow: none !important;
  }
  